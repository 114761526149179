import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, Table, Button, DatePicker, Select, Upload, Popconfirm, Space, Row, Col, Divider, List, Tooltip, Typography, Flex, Tag, Modal, Grid } from 'antd';
import { UploadOutlined, DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { AuthContext } from '../../context/AuthContext';
import { useHttp } from '../../hooks/http.hook'
import dayjs from 'dayjs';
import { GetColumnSearchProps } from '../../components/SearchLine'
import { ContractDetails } from './ContractDetails';
import useSize from '../Equipment/EquipmentList';
import { DataContext } from '../../context/DataContext';
const { Option } = Select;
const { useBreakpoint } = Grid
const { Text } = Typography;

export const ContractList = ({ contractsList, loading, onDelete, onEdit, onSubEdit, setSubVisible, setSubContract, onView }) => {
  const [transfContracts, setTransfContracts] = useState([]);
  const [isContractModalOpen, setIsContractModalOpen] = useState(false);
  const [contract, setContract] = useState({});
  const [contractTypes, setContractTypes] = useState([]);
  const isMobile = useBreakpoint().xs;
  const { settings } = useContext(DataContext)

  // const contractTypes = [
  //   { value: 'service', label: 'Сервисное обслуживание' },
  //   { value: 'installation', label: 'Монтаж оборудования' },
  //   { value: 'serviceBase', label: 'Рамочный сервисный договор' },
  // ]
  // const contractTypes = settings.contractTypes ?? []

  useEffect(() => {
    setContractTypes(settings?.contractTypes ?? [])
  }, [settings])

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 16,
    total: transfContracts?.length,
    onChange: (page) => {
      setPagination({ ...pagination, current: page });
    },
  });

  const windowSize = useSize()

  useEffect(() => {
    window.dispatchEvent(new Event("resize"))
  }, [])

  let transformedContracts = (ctrs) => {
    let updContracts = [...ctrs]

    return updContracts?.filter(el => !el?.parent).map(contract =>
    (
      {
        ...contract,
        list: {
          customer: contract?.customer?.name,
          key: contract?._id,
          titleExt: `${contract?.title} от ${(new Date(contract?.date)).toLocaleDateString('ru-RU')}г.`,
          endDate: contract?.endDate ? (new Date(contract?.endDate)).toLocaleDateString('ru-RU') : '-',
          type: contractTypes?.filter(el => el?.value === contract?.type)[0]?.label,
          status: !contract?.endDate
            ? 'Действующий'
            : new Date(contract?.endDate) < new Date() ? 'Архив' : 'Действующий'
        },
        children: contract?.children?.length === 0 ? null :
          contract?.children?.map(el => ({
            ...el,
            children: null,
            parentContract: {
              _id: contract?._id,
              date: contract?.date, title: contract?.title, customer: contract?.customer, type: contract?.type,
            },
            list: {
              titleExt: `${el?.title} от ${(new Date(el?.date)).toLocaleDateString('ru-RU')}г.`,
              customer: el?.customer?.name,
              type: contractTypes.filter(e => e?.value === el?.type)[0]?.label,
              endDate: el?.endDate ? (new Date(el?.endDate)).toLocaleDateString('ru-RU') : '-',
              status: !contract?.endDate
                ? 'Действующий'
                : new Date(contract?.endDate) < new Date() ? 'Архив' : 'Действующий'
            }
          })).sort((a, b) => new Date(a?.date) - new Date(b?.date)),

      }
    )
    )
  };



  const handleEditContract = (e) => {
    let record = { ...e }
    record.date = dayjs(record?.date)
    record.startDate = dayjs(record?.startDate)
    record.endDate = record?.endDate ? dayjs(new Date(record?.endDate)) : null
    record.customer = record?.customer?._id
    onEdit(record)
  }

  const handleNewSubContract = (e) => {
    let newInitial = {
      parentContract: {
        _id: e._id,
        date: e.date,
        title: e.title,
        customer: {
          name: e.customer.name,
          inn: e.customer.inn,
          _id: e.customer._id
        },
      }
    }
    onSubEdit(newInitial)
  }

  const handleEditSubContract = (e) => {
    let rec = { ...e }
    if (rec._id) {
      rec.date = dayjs(rec.date)
      rec.startDate = dayjs(rec.startDate)
      rec.endDate = rec?.endDate ? dayjs(new Date(rec.endDate)) : null
      rec.customer = rec.customer._id
    }
    onSubEdit(rec)
  }


  const columns = [
    // {
    //   title: '#',
    //   // width: '1%',
    //   key: 'num',
    //   render: (text, record, index) => {
    //     const currentPage = pagination.current || 1;
    //     return <span style={{ textAlign: 'end' }}>{(currentPage - 1) * pagination.pageSize + index + 1}</span>;
    //   },
    // },
    {
      title: `Номер договора`, dataIndex: ['list', 'titleExt'], key: 'titleExt',
      ...GetColumnSearchProps(['list', 'titleExt'], 'номеру'),
      width: 500,
      // fixed: 'left',
      render: (text, record) => {
        return (
          <Flex size={'small'} justify="space-between">
            {record?.parent
              ? <Text
                className={record?.signedPdf?.length === 0 ? 'notFull' : ''}
                ellipsis>Доп. соглашение {text}</Text> //ellipsis={{ rows: 2 }}
              : <Text
                className={record?.signedPdf?.length === 0 ? 'notFull' : ''}
                ellipsis>Договор {text}</Text>}
            {!record.parent &&
              <Button size='small' type="text"
                style={{ color: '#1890ff' }}
                onClick={() => { handleNewSubContract(record) }}>
                + Добавить д/с
              </Button>}
          </Flex>
        )
      }
    },
    {
      title: 'Дата договора', dataIndex: 'date', key: 'date',
      width: 140,
      render: (text, record) => (<span>{dayjs(record?.date).format('DD.MM.YYYY')}</span>),
      sorter: (a, b) => new Date(a?.date) - new Date(b?.date),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Срок действия до', dataIndex: ['list', 'endDate'], key: 'endDate',
      width: 160,
      sorter: (a, b) => new Date(a?.endDate) - new Date(b?.endDate),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Статус', key: 'status', dataIndex: ['list', 'status'],
      width: 140,
      ...GetColumnSearchProps(['list', 'status'], 'статусу'),
      render: (text, record) => (<Tag color={text === 'Архив' ? 'default' : 'geekblue'}>{text}</Tag>)
    },
    {
      title: 'Клиент', dataIndex: ['list', 'customer'], key: 'customer',
      width: 200,
      ...GetColumnSearchProps(['list', 'customer'], 'клиенту'),
      sorter: (a, b) => a?.customer?.name?.toLowerCase().localeCompare(b?.customer?.name?.toLowerCase()),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Тип договора', dataIndex: ['list', 'type'], key: 'type',
      width: 200,
      ...GetColumnSearchProps(['list', 'type'], 'типу')
    },
    {
      title: 'Действия',
      key: 'action',
      width: 100,
      fixed: 'right',
      // responsive: ['sm'],
      render: (text, record) => (
        <Space size={'middle'} >
          {/* {!record.parent ?
            <Tooltip title='Добавить доп. соглашение' color={'geekblue'}>
              <PlusSquareOutlined
                onClick={() => {
                  handleNewSubContract(record);
                }}
              />
            </Tooltip> : <></>
          } */}
          <SearchOutlined
            onClick={() => openModal(record)}
          />
          <Tooltip title='Редактировать карточку договора' color={'geekblue'}>
            <EditOutlined
              onClick={(e) => {
                record.parent
                  ? handleEditSubContract(record)
                  : handleEditContract(record)
              }}
            />
          </Tooltip>

          <Popconfirm
            title="Удаление записи"
            description="Вы уверены, что хотите удалить эту запись?"
            okText="Да"
            cancelText="Нет"
            onConfirm={() => { onDelete(record._id) }}
          >
            <DeleteOutlined style={{ color: 'red' }} />
          </Popconfirm>

        </Space >
      ),
    },
  ];

  // Transform contracts data for display

  useEffect(() => {
    const list = transformedContracts(contractsList)
    setTransfContracts(list)
  }, [contractsList])

  const handleContractOk = () => {
    setIsContractModalOpen(false);
  };

  const handleContractCancel = () => {
    setIsContractModalOpen(false);

  }

  const openModal = (values) => {

    setContract({ ...values, contract: { _id: values._id } })
    setIsContractModalOpen(true);

  }

  return (
    <>
      <Table
        dataSource={transfContracts}
        style={{ marginTop: '1rem' }}
        rowKey={'_id'}
        columns={columns}
        size={'small'}
        scroll={{ x: 500, y: +windowSize[1] - 200, }}
        pagination={false}
        loading={loading}
        onRow={(record, index) => {
          return {
            onDoubleClick: (event) => { openModal(record) }
          }
        }}
        footer={(data) => {
          return < div style={{ textAlign: 'end' }}>Всего записей: {data?.length ?? 0}</div>
        }} // Modified from setting state
      />
      <Modal
        open={isContractModalOpen}
        width={isMobile ? '96vw' : '80vw'}
        okText={"Закрыть"}
        centered
        afterClose={() => setContract({})}
        onOk={handleContractOk}
        onCancel={handleContractCancel}
        footer={(_, { OkBtn }) => (<OkBtn />)}
      >
        <ContractDetails ctr={contract} />
      </Modal>

    </>

  )
};




export const ContractForm = ({ onFinish, customers, machines, equipment, initialValues, fileList, onClose, setFileList }) => {
  const [filteredMachines, setFilteredMachines] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [attachedFiles, setAttachedFiles] = useState([])
  const [contractTypes, setContractTypes] = useState([])
  const [editedContract, setEditedContract] = useState(initialValues)

  const [formContract] = useForm()
  const { request } = useHttp()
  const { token } = useContext(AuthContext)
  const { settings } = useContext(DataContext)

  // const contractTypes = settings.contractTypes

  const selectCustomer = (id) => {
    setSelectedCustomer(id)
  }

  useEffect(() => {
    setFileList([])
    setContractTypes(settings.contractTypes || [])
  }, [])


  useEffect(() => {
    if (initialValues?.customer?._id) {
      setSelectedCustomer(initialValues.customer._id)
    }
    setAttachedFiles(
      initialValues?.signedPdf.length > 0
        ? initialValues.signedPdf.map(el => (
          { _id: el._id, filename: el.filename, filePath: el.filePath, mimetype: el.mimetype }
        ))
        : []
    )
    setEditedContract(initialValues)
  }, [initialValues]) //selectCustomer

  useEffect(() => {
    if (selectedCustomer) {
      let filtered = machines.filter(el => el.owner._id === selectedCustomer)
      setFilteredMachines(filtered)
    } else {
      setFilteredMachines(machines)
    }
  }, [machines, selectedCustomer, setFilteredMachines])


  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleBeforeUpload = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const MAX_WIDTH = 1400;
          const MAX_HEIGHT = 1200;
          let width = img.width;
          let height = img.height;

          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }

          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(blob => {
            resolve(new File([blob], file.name, { type: 'image/jpeg' }));
          }, 'image/jpeg', 0.8);
        };
      };
    });
  };

  const props = {
    onRemove: (file) => {
      setFileList(fileList.filter(f => f !== file));
    },
    beforeUpload: async (file, files) => {
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        let fileRe = await handleBeforeUpload(file)
        setFileList(pre => { return [...pre, fileRe] })
      } else if (file.type === 'application/pdf') {
        setFileList(pre => { return [...pre, file] })
      } else {
        files = files.filter(f => f.uid !== file.uid)
      }

      return false;
    },
    fileList: fileList,
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    listType: "picture-list",
    // defaultFileList: {[...fileList]}
  };

  const handleDeleteFile = (id) => {
    // console.log(`Delete ${id}`)
    let newAttached = attachedFiles.filter(rec => rec._id !== id)
    setAttachedFiles(newAttached)
  }

  const handleWrite = () => {
    handleSubmit()
    // formContract.resetFields();
    setEditedContract({})
    setFileList([]);
    onClose()
  }

  const handleSubmit = async () => {
    try {
      const values = await formContract.validateFields();
      for (var key of Object.keys(values)) {
        if (!values[key]) { delete values[key] }
      }
      onFinish({ ...values, files: fileList, updSignedPdf: attachedFiles });
    } catch (e) {
      console.log(e)
    }

  };

  // const fileCols = [
  //   { title: 'Файл', dataIndex: 'filename', key: 'filename' },
  //   {
  //     title: 'Действия',
  //     key: 'action',
  //     render: (text, record) => (
  //       <a>Delete</a>
  //     )
  //   }
  // ]
  return (
    <Form
      form={formContract}
      // key="contract_form"
      name='contract_form'
      initialValues={editedContract}
      onFinish={onFinish}
      onClose={onClose}
      layout="vertical"
    >
      <Row gutter={[24, 2]} justify="space-between">
        <Col xs={24} sm={16}>
          <Form.Item
            label="Номер договора"
            name="title"
            rules={[{ required: true, message: 'Укажите номер договора' }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} sm={8}>
          <Form.Item
            label="Дата договора"
            name="date"
            rules={[{ required: true, message: 'Введите дату договора' }]}
          >
            <DatePicker format={'DD.MM.YYYY'} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Клиент"
        name="customer"
        rules={[{ required: true, message: 'Выберите клиента' }]}
      >
        <Select

          allowClear
          showSearch
          filterOption={(inputValue, option) => {
            let val = JSON.stringify(option?.children)
            return val.toLowerCase().includes(inputValue?.toLowerCase())
          }}
          placeholder="Выберите клиента"
          onChange={selectCustomer}
          optionLabelProp="children">
          {customers.map(customer => (
            <Option key={customer._id} value={customer._id}>{customer.name} ИНН: {customer.inn}</Option>
          ))}
        </Select>


      </Form.Item>

      <Row gutter={[24, 2]} justify="space-between">
        <Col xs={12} sm={12}>
          <Form.Item
            label="Срок действия с:"
            name="startDate"
            rules={[{ required: true, message: 'Укажите срок действия' }]}
          >
            <DatePicker format={'DD.MM.YYYY'} />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12}>
          <Form.Item
            label="по:"
            name="endDate"

            rules={[{ required: false, message: 'Укажите срок окончания' }]}
          >
            <DatePicker format={'DD.MM.YYYY'} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Тип договора"
        name="type"
        rules={[{ required: true, message: 'Выберите тип договора' }]}
      >
        <Select placeholder="Выберите тип договора">
          {contractTypes.map(el => (
            <Option key={el._id} value={el?.value}>{el?.label}</Option>
          ))}
          {/* <Option value="service">Сервисное обслуживание</Option>
          <Option value="installation">Монтаж оборудования</Option>
          <Option value="serviceBase">Рамочный сервисный договор</Option> */}
        </Select>
      </Form.Item>


      <Form.Item
        label='Комментарий'
        name='comment'
      >
        <Input.TextArea
          rows={5}
          placeholder='Можно добавить любой комментарий к карточке договора, который будет виден всем'
        />
      </Form.Item>

      {/* {filesAttached.length > 0 && <Form.Item
        label="Прикрепленные файлы"
      >
        <Table dataSource={filesAttached} columns={fileCols} size='small' pagination={false} />
      </Form.Item>
      } */}
      <Divider />
      <Form.Item
        label="Загрузить файл"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <>
          <Upload  {...props}
            multiple
            disabled={initialValues === null}
            showUploadList={true}
          >
            <Button icon={<UploadOutlined />}>Загрузить</Button>
          </Upload>
        </>
      </Form.Item>
      {!initialValues ? <>Загрузить файл возможно только после записи договора!</> : ''}
      <Form.Item
      // name='signedPdf'
      >
        {attachedFiles.length > 0 && <List
          // header={<div >Прикрепленные файлы</div>}
          bordered
          size='small'

          dataSource={attachedFiles}
          style={{ marginBottom: '2rem' }}
          renderItem={(file, n) => (
            <List.Item
              key={file._id}
              actions={
                [
                  // <a key="view" onClick={() => handleFilePreview(file)}>View</a>,
                  // <a key="download" href={`filestorage/${file.path}`} download={file.filename}>Download</a>,
                  <Popconfirm
                    title="Удаление файла"
                    description="Вы уверены, что хотите удалить этот файл?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => { handleDeleteFile(file._id) }}
                  >
                    <DeleteOutlined style={{ color: 'red' }} />
                  </Popconfirm>
                ]}
            >
              {n + 1}. {file.filename}
            </List.Item>
          )}
        />}
      </Form.Item>
      <Form.Item>
        {initialValues?._id
          ? (<Button type="default" onClick={handleWrite}>Записать</Button>)
          : (
            <Space size={'middle'}>
              <Button type="default" onClick={handleSubmit}>Записать</Button>
              <Button type="primary" onClick={handleWrite}>Записать и закрыть</Button>
            </Space>
          )}

      </Form.Item>

    </Form>



  );
};

